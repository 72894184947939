<template>
  <div
    class="wrap"
  >
    <div
      class="fixed w-full h-12 bg-gray-50 z-50"
    >
      <div
        class="flex items-center justify-between px-4"
      >
        <div
          class="rounded-lg"
        >
          <NuxtLink
            to="/"
          >
            <svg
              class="w-12 h-12"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                clip-rule="evenodd"
              />
            </svg>
          </NuxtLink>
        </div>
        <NuxtLink
          to="/"
        >
          <IconLoader
            name="Cycle-logo-black"
            class="h-12"
          />
        </NuxtLink>
      </div>
    </div>
    <div
      class="pt-12 flex overflow-hidden bg-gray-50 px-4"
    >
      <main
        class="flex-1 relative pb-8 z-0 overflow-y-auto overflow-x-hidden"
      >
        <div
          class="mt-8"
        >
          <slot />
        </div>
      </main>
    </div>
  </div>
</template>

<style>
.fade-enter-active,
.fade-leave-active {
  transition-duration: 0.3s;
  transition-property: opacity;
  transition-timing-function: ease;
}

.fade-enter,
.fade-leave-active {
  opacity: 0;
}
.wrap {
  padding:
    env(safe-area-inset-top)
    env(safe-area-inset-right)
    0
    env(safe-area-inset-left);
}
</style>
